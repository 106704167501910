.navBarStyle {
  background-color: #00E2E4;
  padding: 0 10rem;
  transition: top 0.3s;
  justify-content: center;
}

.navbar-nav-p {
  font-size: 0.8rem !important;
}

.navBarMainCont {
  display: flex;
  justify-content: center;
}

.logoContainer {
  display: flex;
  justify-content: flex-end;
  height: auto;
  width: 10.6rem;
  margin: 0.5rem;
}

.paketManLogo {
  height: 100%;
  width: 100%;
}

.navbar-nav {
  display: flex;
  width: 100%;
  justify-content: center;
}

.navBarItemContainer div {
  text-align: center;
  background-color: #EC4646;
  border-radius: 10rem;
  width: 10.5rem;
  height: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  cursor: pointer;
}

.navbar-nav p {
  color: white;
  font-weight: bold;
  text-align: center;
  font-family: "Tajawal";
  margin: 0;
}

.navBarMainCont button {
  border: 0.25rem solid #EC4646 !important;
}

@media (max-width: 800px) {
  .navBarStyle {
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-content: center;
  }

  .navBarMainCont {
    margin: 1rem;
    justify-content: center !important;
  }

  .navBarItemContainer {
    justify-content: center !important;
  }
}

@media (min-width: 1024px) {
  .navBarStyle {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-content: center;
  }

  .navBarMainCont {
    margin: 0.5rem;
    justify-content: center !important;
  }

  .navBarItemContainer {
    justify-content: space-between !important;
  }
}

.selectLang {
  position: absolute;
  right: 4%;
  top: 10%;
}

.selectLang select {
  /*
  background-color: yellow;
  border-radius: 10%;
  */
  background-color: #EC4646;
  border-radius: 50%;
  width: 3rem;
  text-align: center;
  height: 3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: white;
  font-weight: 600;
  font-family: "Tajawal";
  font-size: 1rem;
  cursor: pointer;
  border-color: #EC4646;
}

.selectLang option {

  font-weight: 600;
  font-family: "Tajawal";
  font-size: 1rem;

}