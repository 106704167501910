.contactUsCont {
  background-color: #edf0f7;
  padding: 2rem 10rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
}

.contactUsTitleRow {
  width: 15.5rem;
  height: 3.5rem;
  background-color: #ec4646;
  border-radius: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.contactUsTitle {
  font-family: "Tajawal";
  color: white;
  font-size: 3rem;
  font-weight: 800;
}

.contactUsImgCont,
.contactUsForm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactUsImg {
  width: 100%;
  height: auto;
}

@media (max-width: 800px) {
  .contactUsCont {
    display: flex;
    padding: 1rem;
    align-items: center;
  }

  .contactUsRow {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  .contactUsForm .formCont {
    margin: 1rem 0.5rem;
  }
}

@media (min-width: 1024px) {
  .contactUsCont {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 2560px) {
  .contactUsCont {
    padding: 2rem 38rem;
  }

  .contactUsForm {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}