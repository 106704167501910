.footerCont {
  background-color: #edf0f7;
  padding: 1rem 10rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
}

.footerCont .col,
.logoCol img {
  margin: 1rem 0rem;
}

.socialCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialCol svg {
  margin: 0 0.5rem;
}

.socialCol p {
  text-align: center;
  margin-bottom: 0;
  color: #ec4646;
  font-weight: 700;
}

.socialCol a {
  color: #663f3f;
}

.logoCol {
  width: 14.5rem;
  height: 13.4rem;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.policyCol {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.policyCol a {
  color: black;
}

.paketManFooterLogo {
  width: 12rem;
  height: auto;
}

.tMIcon {
  width: 30%;
}

@media (max-width: 800px) {
  .footerCont {
    padding: 1rem;
  }

  .footerCont .row {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .policyCol {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}