.formCont {
  padding: 1rem 2rem;
  margin: 2rem;
  border-radius: 2rem;
  background-color: #663f3f;
  width: 100%;
  height: auto;
}

.formCont .row {
  margin-top: 0.5rem;
}

.formRowRes .col {
  margin-top: 0.5rem;
}

.submitButtonCol {
  display: flex;
  justify-content: center;
}
.submitButton_ru{
  width: 6rem !important;
}
.submitButton {
  background-color: #EC4646;
  color: aliceblue;
  border-radius: 0.5rem;
  width: 5rem;
  height: auto;
  border-color: #EC4646;
}

@media (max-width: 800px) {
  .formCont {
    padding: 1rem;
  }

  .formRowRes {
    display: flex;
    flex-direction: column;
  }
}