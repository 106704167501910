.joinUSContainer {
  background-color: #EC4646;
  padding: 2rem 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
}

.joinUsTitle {
  margin: 0.625rem;
}

.joinUsTitle h2 {
  color: white;
  font-family: "Tajawal";
  font-size: 6.25rem;
  font-weight: 700;
}

.joinUsCardsCont {
  display: flex;
  justify-content: center;
  align-content: center;
}

.formCol {
  display: flex;
  justify-content: center;
}

.joinUsTitle::before {
  content: " ";
  display: block;
  position: absolute;
  left: 56rem;
  top: -6rem;
  width: 18rem;
  height: 20rem;
  transform: rotate(57deg);
  background-image: url("../../assests/background_Imges/2.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
}

.joinUsCardsCont::before {
  content: " ";
  display: block;
  position: absolute;
  left: 1rem;
  top: 80rem;
  width: 20rem;
  height: 20rem;
  transform: rotate(9deg);
  background-image: url("../../assests/background_Imges/1.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
}

.formCol::before {
  content: " ";
  display: block;
  position: absolute;
  left: 60rem;
  top: 7rem;
  width: 15rem;
  height: 15rem;
  transform: rotate(240deg);
  background-image: url("../../assests/background_Imges/5.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
}

.formRowCont::before {
  content: " ";
  display: block;
  position: absolute;
  left: 3rem;
  top: 107rem;
  width: 15rem;
  height: 15rem;
  transform: rotate(305deg);
  background-image: url("../../assests/background_Imges/3.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
}

@media (max-width: 1024px) {

  .joinUsTitle::before,
  .formCol::before,
  .joinUsCardsCont::before {
    content: none;
  }
}

@media (min-width: 2560px) {
  .joinUSContainer {
    padding: 2rem 38rem;
  }

  .formRowCont::before {
    left: 28rem;
    top: 139rem;
  }

  .formCol::before {
    left: 73rem;
    top: 5rem;
    width: 21rem;
    height: 22rem;
  }

  .joinUsCardsCont::before {
    left: 22rem;
    top: 91rem;
  }
}

@media (max-width: 800px) {
  .joinUSContainer {
    padding: 1rem;
  }

  .joinUsCardsCont::before,
  .joinUsTitle::before,
  .formCol::before,
  .formRowCont::before {
    content: none;
  }
}