.locationCont {
  background-color: #ec4646;
  padding: 1rem 10rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
}

.infoCardRow {
  width: 17rem;
  margin: 1rem;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}

.infoCont {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  direction: ltr;
}

.iconCol {
  background-color: white;
  max-width: 3.75rem;
  height: 3.75rem;
  border-radius: 3.75rem;
  display: flex;
  justify-content: center;
}

.iconImg {
  display: block;
  width: 100%;
  height: auto;
}

.textCol {
  color: white;
  font-family: "Tajawal";
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.textCol p {
  margin-bottom: 0px;
}

@media (max-width: 800px) {
  .locationCont {
    display: flex;
    padding: 1rem;
    align-items: center;
  }

  .textCol {
    color: white;
    font-family: "Tajawal";
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}