.faqContainer {
  background-color: white;
  padding: 2rem 10rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
}

.faqTitle {
  width: 15.5rem;
  height: 3.5rem;
  background-color: #ec4646;
  border-radius: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.faqTitleText {
  color: white;
  font-size: 3rem;
  font-weight: 600;
}
.faqTitleText_ru{
  font-size: 1rem !important;
}
.contactUsTitle_ru{
  font-size: 1.7rem !important;
}
.faqImgCont {
  display: flex;
  justify-content: center;
}

.faqImg {
  width: 75%;
  height: auto;
}

@media (max-width: 800px) {
  .faqContainer {
    display: flex;
    padding: 1rem;
    align-items: center;
  }

  .faqTextCont {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 2560px) {
  .faqContainer {
    padding: 2rem 38rem;
  }
}