.featureTitle {
  color: #663f3f;
  font-family: "Tajawal";
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 2.5px;
}

.featureText {
  color: #663f3f;
  font-family: "Tajawal";
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
}

@media (max-width: 800px) {
  .featureBox {
    text-align: center;
  }
}
