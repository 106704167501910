.faqCard {
  border: 0.125rem solid #000000;
  border-radius: 0.5rem;
}

.faqQuestion {
  background-color: #00E2E4;
  color: #663f3f;
  font-family: "Tajawal";
  font-size: 1.5rem;
  font-weight: bold;
}

.faqAnswer {
  background-color: white;
  color: #000000;
  font-family: "Tajawal";
  font-size: 1.5rem;
  font-weight: 400;
}