.headerContainer {
  background-color: #00E2E4;
  padding: 2rem 10rem;
  position: relative;
}

/* .headerContainer::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-image: url("../../assests/Bitmap.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
} */

.headerMainRow {
  display: flex;
  justify-content: space-evenly;
  background-color: #00E2E4;
}

.headerTextCol {
  display: flex;
  flex-direction: column;
}

.headerTitleCol {
  display: flex;
  flex-direction: column;
  width: 15.5rem;
  height: 3.5rem;
  border-radius: 2rem;
  background-color: white;
  justify-content: center;
}

.headerTitle {
  text-align: center;
  color: #663f3f;
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  font-family: "Tajawal"
}

.headerImg {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.headerBody {
  color: #663f3f;
  font-family: "Tajawal";
  text-align: justify;
  margin-top: 2rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.mobilePhoto {
  width: 15rem;
  height: auto;
  position: relative;
}

.downloadLinks {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.downloadLinks a {
  margin: 1rem;
}

.downloadLinks img {
  width: 8.438rem;
  height: auto;
}

.socialmediaLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialmediaLinks svg {
  margin: 0 0.5rem;
  stroke: #663f3f;
  stroke-width: 0.25;
  fill: #663f3f;
}

.socialmediaLinks p {
  text-align: center;
  margin-bottom: 0;
  color: #EC4646;
  font-weight: 700;
}

/* pepper pic icon */
.headerTextCol::before {
  content: "";
  display: block;
  position: absolute;
  left: 41rem;
  top: 0rem;
  width: 30%;
  height: 25%;
  background-image: url("../../assests/background_Imges/3.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.headerBody::before {
  content: " ";
  display: block;
  position: absolute;
  left: -16rem;
  top: -7.5rem;
  width: 15rem;
  height: 20rem;
  background-image: url("../../assests/background_Imges/1.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
}

.headerImg::before {
  content: " ";
  display: block;
  position: absolute;
  left: 1.5rem;
  top: 4rem;
  width: 115%;
  height: 115%;
  background-image: url("../../assests/pics/yellow-Cercle.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: contain;
}
/*
.downloadLinks::before {
  content: " ";
  display: block;
  position: absolute;
  left: 34rem;
  top: -25.5rem;
  width: 15rem;
  transform: rotate(45deg);
  height: 20rem;
  background-image: url("../../assests/background_Imges/2.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
}
*/
.socialmediaLinks::before {
  content: " ";
  display: block;
  position: absolute;
  left: -13rem;
  top: -7.5rem;
  width: 15rem;
  transform: rotate(45deg);
  height: 20rem;
  background-image: url("../../assests/background_Imges/4.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
}

@media (max-width: 800px) {
  .headerContainer {
    background-color: #00E2E4;
    padding: 1rem 0;
  }

  .headerMainRow {
    width: 100%;
    margin: 0;
  }

  .headerTitleCol {
    justify-content: center;
  }

  .headerBody {
    width: auto;
  }

  .headerTextCol::before {
    top: -57px;
    left: -28px;
  }

  .headerBody::before {
    top: 37rem;
    left: 2rem;
  }

  .downloadLinks::before {
    top: 11rem;
    left: -8rem;
  }

  .headerImg::before {
    top: 10rem;
    left: 0;
  }

  .downloadLinks,
  .socialmediaLinks {
    margin: 1rem 0;
  }

  .downloadLinks {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1440px) {
  .downloadLinks::before {
    top: -25.5rem;
    width: 15rem;
  }
}

@media (min-width: 1024px) {
  .downloadLinks::before {
    top: -13rem;
    left: 27rem;
  }
}

@media (min-width: 2560px) {
  .headerContainer {
    padding: 2rem 38rem;
  }

  .headerTextCol::before {
    height: 35%;
  }

  .downloadLinks::before {
    left: 70rem;
  }
}