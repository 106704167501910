.cardCont {
 /* width: 6.25rem; */
  width: 10rem;
  height: 7.188rem;
  background-color: ivory;
  border-radius: 1.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.cardTitle {
  color: #414042;
  font-family: "Tajawal";
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.cardImg {
  width: 3.125rem;
  height: auto;
}

@media (max-width: 800px) {
}
