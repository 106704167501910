.featuresContainer {
 /* background-color: #edf0f7; */
  padding: 2rem 10rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
}

.featuresTitle {
  width: 15.5rem;
  height: 4.5rem;
  background-color: #EC4646;
  border-radius: 3.5rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.featuresTitle_ar {
  width: 22.5rem !important;
  /* padding-top: 10px !important; */
}

.sliderCont::before {
  content: " ";
  display: block;
  position: absolute;
  top: 2rem;
  width: 10rem;
  height: 25%;
  transform: rotate(20deg);
  background-image: url("../../assests/background_Imges/1.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
}

.featureTitleText_ru {
  font-size: 1.8rem !important;
  padding-top: 15px;
}

.featureTitleText {
  color: white;
  font-size: 3rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.sliderCont {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
}

.sliderPic {
  width: 50%;
  height: auto;
  justify-content: center;
}

.sliderImgCont {
  display: flex;
  justify-content: center;
}

.custumeDot {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.0625rem solid #663f3f;
  border-radius: 50%;
}

.slick-active .custumeDot {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #EC4646;
  border-radius: 50%;
}

.yellowCercle {
  position: absolute;
  top: -3rem;
  left: 41rem;
  fill: #EC4646;
}

.gryeCercle {
  position: absolute;
  fill: gray;
  top: -18rem;
  left: -3rem;
}

@media (max-width: 800px) {
  .sliderCont {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .featuresContainer {
    padding: 1rem 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sliderImgCont {
    margin-top: 3rem;
  }

  .yellowCercle,
  .gryeCercle {
    top: 0;
    left: 0;
    visibility: hidden;
  }
}

@media (min-width: 2560px) {
  .featuresContainer {
    padding: 2rem 38rem;
  }

  .yellowCercle,
  .gryeCercle {
    visibility: hidden;
  }
}